<template>
  <div>
    <TopBar :showMenu="false"/>
    <AppTitle title="Welcome to Hungry+" :showMascot="true"/>
    <div class="pt-6">
      <div class="text-green-500 mt-4" v-show="showResetMessage">
        You can now log in with your new password.
      </div>
      <div class="py-10">
        <button class="bg-white text-black font-gothic w-full py-4 rounded-xl my-4 border" @click="loginWithFacebook">
          <img src="/img/social/f-logo.png" class="h-5 mr-2 inline -mt-1"> Continue with Facebook
        </button>
        <!-- <button class="bg-white text-black font-gothic w-full py-4 rounded-xl my-4 border" @click="loginWithGoogle">
          <img src="/img/social/g-logo.png" class="h-5 mr-2 inline -mt-1"> Continue with Google
        </button> -->
        <p class="text-center py-2">or</p>
        <form @submit.stop.prevent="submit">
          <div>
            <input type="text" class="focus:ring-red-500 focus:border-red-500 px-6 py-4 rounded-xl border border-gray-200 w-full mt-4" placeholder="Enter your email" v-model="email" ref="email">
            <p class="text-sm text-red-500 pt-1" v-show="errors.email">{{ errors.email }}</p>
          </div>
          <div v-show="submitAction">
            <input type="password" class="focus:ring-red-500 focus:border-red-500 px-6 py-4 rounded-xl border border-gray-200 w-full mt-4" :placeholder="this.submitAction === 'register' ? 'Create Password' : 'Password'" v-model="password" ref="password">
            <p class="text-sm text-red-500 pt-1" v-show="errors.password">{{ errors.password }}</p>
          </div>
          <button v-show="!loading" class="bg-red-500 text-white font-gothic w-full py-4 rounded-xl my-4 border border-transparent" v-text="emailButton"></button>
          <button class="bg-red-400 text-white font-gothic w-full py-4 rounded-xl my-4 border border-transparent" v-show="loading" disabled>
            <font-awesome-icon icon="fas fa-circle-notch" class="text-white animate-spin"/>
          </button>
        </form>
        <p v-if="submitAction==='login'" class="text-center text-red-500 underline text-sm pb-2">
          <router-link :to="{ name: 'forgot-password' }">Forgot password?</router-link>
        </p>
        <p class="text-sm text-gray-400 text-center mt-2 leading-loose">
          By continuing, I agree to Hungry+ <br/><a href="https://hungryplus.com/terms" class="text-red-500 underline">Terms of Service</a> and <a class="text-red-500 underline" href="https://hungryplus.com/privacy">Privacy Policy</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from '@/components/TopBar.vue';
  import AppTitle from '@/components/AppTitle.vue';
  export default {
    components: {
      TopBar,
      AppTitle
    },
    data(){
      return {
        email: '',
        password: '',
        submitAction: null,
        loading: false,
        errors: {
          email: '',
          password: ''
        },
        dismissNotice: false
      }
    },
    mounted(){
      if (this.user){
        this.$router.push({name: 'dashboard'})
      }
    },
    computed: {
      user(){
        return this.$store.state.auth.user;
      },
      showResetMessage(){
        return this.$route.hash === '#reset' && !this.dismissNotice;
      },
      emailButton(){
        if (this.submitAction === 'login'){
          return 'Sign In'
        }
        if (this.submitAction === 'register'){
          return 'Create Account'
        }
        return 'Continue with Email'
      }
    },
    watch: {
      email(){
        this.submitAction = null;
      }
    },
    methods: {
      loginWithFacebook(){
        window.location.href = `${process.env.VUE_APP_API_BASE_URL}/auth/facebook`;
      },
      loginWithGoogle(){
        window.location.href = `${process.env.VUE_APP_API_BASE_URL}/auth/google`;
      },
      async checkEmail(){
        this.loading = true;
        this.$store.dispatch('auth/checkEmail', {
          email: this.email
        }).then( ({ data }) => {
          if (data){
            this.submitAction = 'login';
          } else {
            this.submitAction = 'register';
          }
        }).finally( () => {
          this.loading = false;
          this.$refs.password.focus();
        });
      },
      async login(){
        this.loading = true;
        await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password
        })
        .then( () => {
          this.$store.dispatch('auth/getCurrentUser').then( () => {
            if (!this.$store.state.verified_message){
              this.$router.push('/');
            }
          });
        })
        .catch( error => {
          if (error.response && error.response.status === 422 ){
            this.errors.password = "Incorrect email or password";
          } else if (error.response && error.response.statusText === 'Too Many Requests' ){
            this.errors.password = "Login failed. Please try again later.";
          } else {
            this.errors.password = "Login failed.";
          }
        })
        .finally( () => {          
          this.loading = false;
        })
      },
      async register(){
        this.loading = true;

         await this.$store.dispatch('auth/register', { 
            email: this.email,
            password: this.password,
            password_confirmation: this.password,
          })
          .then( () => {
            this.$store.dispatch('auth/getCurrentUser').then( () => {
              this.$store.commit('SET_WELCOME', true);
              this.$router.push('/dashboard');
            });
          })
          .catch( error => {
            // console.error(error);
            if (error.response && error.response.status === 422 ){
              if (error.response.data && error.response.data.errors){
                this.errors.password = Object.values(error.response.data.errors)[0][0];
              } else {
                this.errors.password = "There was an error creating your account."
              }
            } else {
              this.errors.password = "Wrong email or password"
            }
          })
          .finally( () => {
            this.loading = false;
          })
      },
      validateForm(){
        this.errors.email = "";
        this.errors.password = "";
        if (this.email.length === 0){
          this.errors.email = 'Please enter your email';
          this.$refs.email.focus();
          return false;
        }
        if (this.password.length === 0 && this.submitAction){
          this.errors.password = 'Please enter your password';
          this.$refs.password.focus();
          return false;
        }
        return true;
      },
      async submit(){
        if (!this.validateForm()){
          return;
        }
        this.dismissNotice = true;
        if (!this.submitAction){
          await this.checkEmail();
        } 
        if (this.submitAction === 'login'){
          await this.login();
        }
        if (this.submitAction === 'register'){
          await this.register();
        }
      }
    }
  }
</script>